.max-w-\[1440px\] {
    max-width: none;
}

.input-control-dv {
    height: inherit !important;
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important;
    border-radius: 6px !important;
}

.stay_connected .input-control-dv {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

header {
    background-color: #F3FDFD;
}

footer .uppercase {
    font-size: 30px !important;
}

header span {
    font-size: 30px !important;
    font-weight: bold;
}

button {
    margin: 0px !important;
    padding: 12px 32px !important;
    line-height: normal !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    height: auto !important;
    width: auto !important;
    border-radius: 6px !important;
    color: #fff !important;
    background-color: #FE6D12 !important;
}

button.bg-darkgray {
    background-color: #969696 !important;
}

.case-studies-dv {
    justify-content: stretch;
    display: flex;
    align-items: normal;
}

.case-studies-dv b {
    font-size: 1.2rem !important;
}

.stay_connected button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.question-category-list * {
    font-size: 14px !important;
}

.graphic.object-cover {
    object-fit: contain;
    width: 100%;
}

#root>div>section:nth-child(2) {
    /* min-height: calc(100vh - 94px - 110px); */
    min-height: calc(100vh - 80px - 70px);
    justify-content: flex-start;
}

.stay-connected-message {
    border: 1px solid rgb(50, 169, 50);
    width: 100%;
    padding: 40px;
    text-align: center;
}

.book_consultation_btn {
    min-width: 100%;
}

@media (max-width:768px) {

    /* #root > div > section:nth-child(2) {
        padding: 48px;
    } */
    #root>div>section:nth-child(2) {
        padding: 24px 16px;
    }

    footer>div:nth-child(1) div {
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
    }

    .category-list .w-\[236px\] {
        width: 48%;
    }
}

@media (max-width:575px) {
    .w-\[518px\] {
        width: 100%;
    }

    .select-state .w-\[186px\] {
        width: 100%;
    }

    .category-list .w-\[236px\] {
        width: 100%;
    }
}

html {
    font-size: 10px;
}

body,
body * {

    font-size: 1.6rem !important;
    cursor: default;
}

button>div:nth-child(1) {
    cursor: unset;
}

button>div>div>div:last-of-type {
    cursor: pointer;
}

button>b {
    cursor: pointer;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 160% !important;
}

body p {
    font-size: 1.6rem !important;
}

body h1,
body h1 p {
    font-size: 2.8rem !important;
}

body h2 {
    font-size: 2.2rem !important;
}

body h3 {
    font-size: 2.0rem !important;
}

body h4 {
    font-size: 1.8rem !important;
}

.btnconsultation_dv {
    position: relative;
    width: 100%;
}

.btnconsultation_dv .consultation_btn {
    position: fixed;
    bottom: 80px;
    width: max-content !important;
    right: 24px !important;
    margin: 15px auto;
    font-family: "Montserrat";
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    padding: 15px 20px;
    border: 0px solid #000;
}

/* Initiate Auto-Pulse animations */
.consultation_btn.pulse-button {
    animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
}

/* Declare border pulse animation */
@keyframes borderPulse {
    0% {
        box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, .4), 0px 0px 0px 0px rgba(254, 109, 18, 1);
    }

    100% {
        box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255, .2), 0px 0px 0px 10px rgba(255, 255, 255, 0);
    }
}


@media (max-width: 991px) {
    .btnconsultation_dv .consultation_btn {
        margin: 0px 0px 0px -124px !important;
        left: 50%;
    }
}

@media (min-width: 768px) {

    body,
    body * {

        font-size: 1.8rem !important;
    }

    body p {
        font-size: 1.6rem !important;
    }

    body h1,
    body h1 p {
        font-size: 2.8rem !important;
        line-height: 100% !important;
    }

    body h2 {
        font-size: 2.2rem !important;
    }

    body h3 {
        font-size: 2.0rem !important;
    }

    body h4 {
        font-size: 1.8rem !important;
    }
}

.chart {
    max-width: 100% !important;
    height: auto !important;
}