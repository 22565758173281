.content {
    font-family: sans-serif;
    font-size: 14px;
    position: relative;
  }
  .content * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .org-chart {
    display: block;
    clear: both;
    margin-bottom: 30px;
    position: relative;
  }
  .org-chart.cf:before, .org-chart.cf:after {
    content: " ";
    display: table;
  }
  .org-chart.cf:after {
    clear: both;
  }
  
  
  
  
  
  
  
  
  
  
  .org-chart ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .org-chart ul li {
    position: relative;
  }
  .org-chart ul li span {
    display: block;
    border: 2px solid #010101;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    color: black;
    font-size: 12px;
    background: #FFFFFF;
    padding: 4px;
  }
  .org-chart .lvl-b {
    background: #FFFFFF;
    color: #010101;
  }
  /*///////////////////////////////
  ////////////////////////////////
  /////////////////////////////*/
  .org-chart .board {
    width: 70%;
    margin: 0 auto;
    display: block;
    position: relative;
  }
  .org-chart .board:before {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    border-left: 2px solid #010101;
    margin-left: 49%;
    top: 0;
    bottom: 0;
  }
  /*///////////////////////////////
  ////////////////////////////////
  /////////////////////////////*/
  .org-chart ul.columnOne {
    height: 90px;
    position: relative;
    width: 100%;
    display: block;
    clear: both;
  }
  .org-chart ul.columnOne li {
    width: 30%;
    margin: 0px auto;
    top: 0;
  }
  .org-chart ul.columnTwo {
    position: relative;
    width: 100%;
    display: block;
    height: 90px;
    clear: both;
  }
  .org-chart ul.columnTwo li:first-child {
    width: 30%;
    float: left;
  }
  .org-chart ul.columnTwo li {
    width: 30%;
    float: right;
  }
  .org-chart ul.columnTwo:before {
    content: "";
    display: block;
    position: relative;
    width: 80%;
    height: 10px;
    border-top: 2px solid orange;
    margin: 0 auto;
    top: 40px;
  }
  .org-chart ul.columnThree {
    position: relative;
    width: 100%;
    display: block;
    clear: both;
  }
  .org-chart ul.columnThree li:first-child {
    width: 30%;
    float: left;
    margin-left: 0;
  }
  .org-chart ul.columnThree li {
    width: 30%;
    margin-left: 5%;
    float: left;
  }
  .org-chart ul.columnThree li:last-child {
    width: 30%;
    float: right;
    margin-left: 0;
  }
  .org-chart ul.columnThree:before {
    content: "";
    display: block;
    position: relative;
    width: 80%;
    height: 10px;
    border-top: 2px solid orange;
    margin: 0 auto;
    top: 40px;
  }
  .org-chart .departments {
    width: 100%;
    display: block;
    clear: both;
  }
  .org-chart .departments:before {
    content: "";
    display: block;
    width: 90%;
    height: 20px;
    border-top: 2px solid #010101;
    border-left: 2px solid #010101;
    border-right: 2px solid #010101;
    margin: 0 auto;
    top: 0px;
  }
  .org-chart .department {
    border-left: 2px solid orange;
    width: 7%;
    float: left;
    margin: 0px 4px;
  }
  .org-chart .department:after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 22px;
    border-left: 2px solid orange;
    left: 50%;
    top: -22px;
  }
  .org-chart .department:first-child:after {
    display: none;
  }
  .org-chart .department:last-child:after {
    display: none;
  }
  .org-chart .department.central {
    background: #F5EEC9;
  }
  .org-chart .department.central:after {
    display: none;
  }
  .org-chart .department span {
    border-left: 1px solid orange;
  }
  .org-chart .department li {
    padding-left: 25px;
    border-bottom: 2px solid orange;
    height: 80px;
  }
  .org-chart .department li span {
    background: #92D4A8;
    top: 38px;
    position: absolute;
    z-index: 1;
    width: 95%;
    height: auto;
    vertical-align: middle;
    right: 0px;
    line-height: 14px;
    border: 3px solid orange;
  }
  .org-chart .department .sections {
    margin-top: -20px;
  }
  
  /* MEDIA QUERIES */
  @media all and (max-width: 767px) {
    .org-chart .board {
      margin: 0px;
      width: 100%;
    }
    .org-chart .departments:before {
      border: none;
    }
    .org-chart .department {
      float: none;
      width: 100%;
      margin-left: 0;
      background: #F5EEC9;
      margin-bottom: 40px;
    }
    .org-chart .department:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 60px;
      border-left: 2px solid orange;
      z-index: 1;
      top: -45px;
      left: 0%;
      margin-left: -2px;
    }
    .org-chart .department:after {
      display: none;
    }
    .org-chart .department:first-child:before {
      display: none;
    }
  }
  /*--------- TO BE REMOVED FROM YOUR CSS --*/
  /* this is just to display the behaviour of responsive on codepen */
  .responsive-content {
    width: 767px;
    margin: 0px auto;
  }
  .responsive-content .org-chart .board {
    margin: 0px;
    width: 100%;
  }
  .responsive-content .org-chart .departments:before {
    border: none;
  }
  .responsive-content .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    background: #F5EEC9;
    margin-bottom: 40px;
  }
  .responsive-content .org-chart .department:before {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 60px;
    border-left: 2px solid orange;
    z-index: 1;
    top: -45px;
    left: 0%;
    margin-left: -2px;
  }
  .responsive-content .org-chart .department:after {
    display: none;
  }
  .responsive-content .org-chart .department:first-child:before {
    display: none;
  }