body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.wrap {
	width: 100%;
	padding: 16px;
	margin: auto;
	border: 8px solid #f5f5f5;
	border-radius:16px ;
}


.category-row {
    margin: auto;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    position: relative;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-row.first:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 90%;
    border-bottom: 1px solid #000000;
}
.category-row.last:after {
    content: "";
    position: absolute;
    top: 0;
    width: 90%;
    border-bottom: 1px solid #000000;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-itm {
    margin: 0;
    padding: 0;
    font-size: 12px;
    position: relative;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-row.you .category-itm:nth-child(2) {
	padding: 100px 200px;
	display: flex;
	align-self: stretch;
	align-items: center
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-row.you .category-itm:nth-child(2) {
	display: flex;
	align-self: stretch;
	align-items: center
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-row.first .category-itm:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -14px;
    width: 1px;
    height: 20px;
    border-left: 1px solid #000000;
}
.category-row.last .category-itm:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -14px;
    width: 1px;
    height: 20px;
    border-left: 1px solid #000000;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-row.you .category-itm:nth-child(2):after {
    content: "";
    position: absolute;
    left: 50%;
    top: -20px;
    bottom: -16px;
    width: 1px;
    border-left: 1px solid #000000;
}
.category-row.you .category-itm:nth-child(2):before {
    content: "";
    position: absolute;
    left: -14px;
    right: -14px;
    top: 50%;
    height: 1px;
    border-bottom: 1px solid #000000;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-itm .category-name {
    margin: 0 0 10px 0;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #000000;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-itm ul.answer-list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: white !important;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.category-itm li.answer-itm {
    margin: 5px 0 5px 20px;
    padding: 8px 16px;
    border: 1px solid #000000;
    border-radius: 4px;
    position: relative;
    background: #FFFFFF;
}
.category-itm li.answer-itm:last-child {
    margin: 5px 0 5px 20px;
}
.category-itm li.answer-itm:before {
    content: "";
    width: 10px;
    height: 1px;
    border-top: 1px solid #000000;
    position: absolute;
    top: 50%;
    left: -10px;
}
.category-itm li.answer-itm:after {
    content: "";
    width: 1px;
    height: 125%;
    border-left: 1px solid #000000;
    position: absolute;
    top: -75%;
    left: -10px;
}
/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/


.category-list {
	padding: 0 0 0 30px;
	position: relative;
}
.category-list:after {
	content: "";
	position: absolute;
	left: 15px;
	top: -15px;
	bottom: 0;
	width: 1px;
	border-left: 1px solid #333333;
}




.category-list .category-itm:after {
	content: "";
	position: absolute;
	left: -15px;
	top: 20px;
	width: 15px;
	border-bottom: 1px solid #333333;
}

.d-none{
display: none !important;
}

.d-block{
    display: block !important;
}